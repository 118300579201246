<template lang="pug">
  div
    v-row
      v-col(cols="12")
        v-row
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text ID
              v-card-text {{ ((feedingProgramData.id || '').toString() || '').padStart(6, '0') }}
              v-divider
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text Stock
              v-card-text {{ ((feedingProgramData.stock || {}).description || null) }}
              v-divider
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text Stock Category
              v-card-text {{ (((feedingProgramData.stock || {}).category || {}).name || null) }}
              v-divider
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text Type
              v-card-text {{ (feedingProgramData.category || {}).description || null }}
              v-divider
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text Day Range
              v-card-text {{ feedingProgramData.day_start || null }} - {{ feedingProgramData.day_end || null }}
              v-divider
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text Body Weight
              v-card-text {{ (feedingProgramData.body_weight || 0).unitFormat('kilogram') }}
              v-divider
          v-col(lg="3" cols="12")
            v-card(elevation="0")
              v-card-subtitle.primary--text Expected Weight
              v-card-text {{ (feedingProgramData.expected_weight || 0).unitFormat('kilogram') }}
              v-divider
      v-col(cols="12")
        v-row
          v-col(cols="4")
            v-toolbar(
              :color="editFeedingProgramDetailMode ? 'yellow darken-3' : 'primary'"
              dense
              elevation="0"
              dark
            )
              span {{ !editFeedingProgramDetailMode ? 'New' : 'Edit' }}
            create-feeding-program-detail(
              :last-day="feedingProgramDetailLastday"
              :default-field-values.sync="feedingProgramDetailData"
            )
          v-col(cols="8")
            v-toolbar(
              color="primary"
              dense
              elevation="0"
              dark
            )
              span List
            table-feeding-program-detail(
              v-model="feedingProgramDetailLastday"
              @edit="item => { feedingProgramDetailData = item }"
            )
</template>
<script>
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import feedingProgramRepository from '@/repositories/feeding-program.repository'

const [feedingProgramGetVars, feedingProgramGetVarNames] = requestVars({ identifier: 'feeding-program', data: 'feedingProgramData' })
const feedingProgramGetHandler = new VueRequestHandler(null, feedingProgramGetVarNames)

export default {
  name: 'FeedingProgramCategoryShow',
  components: {
    createFeedingProgramDetail: () => import('./details/Create'),
    tableFeedingProgramDetail: () => import('./details/Table'),
  },
  data: () => ({
    feedingProgramDetailData: {},
    feedingProgramDetailLastday: null,
    ...feedingProgramGetVars,
  }),
  created () {
    this.getFeedingProgram()
  },
  computed: {
    editFeedingProgramDetailMode () {
      return !this.$objectEmpty(this.feedingProgramDetailData)
    },
  },
  methods: {
    getFeedingProgram () {
      feedingProgramGetHandler.setVue(this)
      const repository = feedingProgramRepository.show
      const { feedingProgramId } = this.$route.params
      const params = this.getFeedingProgramShowParams()
      feedingProgramGetHandler.execute(repository, [feedingProgramId, params])
    },
    getFeedingProgramShowParams () {
      const withRelation = ['category', 'stock.category'].join(',')
      return {
        with: withRelation,
      }
    },
  },
}
</script>