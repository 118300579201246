import repository from './repository'

const resource = 'feeding-programs'

export default {
  index () {
    return repository.get(resource)
  },
  store (data) {
    return repository.post(resource, data)
  },
  show (id, params) {
    return repository.get(`${resource}/${id}/show`, { params })
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
}
